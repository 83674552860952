.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0);
}

.leaflet-tooltip {
  padding: 0;
  border: 0;
}

a.button {
  display: inline-block;
  padding: 0.4em 1.4em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.15em;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  background-color: #3369ff;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
  text-align: center;
  position: relative;
}

a.button:active {
  top: 0.1em;
}
